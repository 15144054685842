import { FC } from "react";
import { Button } from "@root/shared/components/Button";
import { Link } from "@root/shared/components/Link";
import { AcceptScoutLink } from "@userFrontend/components/Links/AcceptScoutLink";
import { KeepForm } from "@userFrontend/features/publication/components/KeepForm";
import { EntryLink } from "@userFrontend/components/Links/EntryLink";
import { SalonTourButton } from "@userFrontend/components/Buttons/SalonTourButton";
import { useSalonTourButton } from "@userFrontend/hooks/useSalonTourButton";
import { OiwaiInformationBox } from "@userFrontend/components/OiwaiInfomationBox";
import { KeepAppealPopOver } from "@userFrontend/features/publication/components/KeepAppealPopOver";
import { ScoutDeadlineInformation } from "@userFrontend/features/publication/components/ScoutDeadlineInformation";
import { ExtendScoutDeadlineButton } from "@userFrontend/components/Buttons/ExtendScoutDeadlineButton";
import { ButtonProps } from "@userFrontend/features/publication/components/DetailPageActionButtons/type";
// FIXME: 要リファクタ。legacyのTelButtonを使用するのをやめて、userFrontend/Buttons/TelButtonを作成&使用を検討してください。
import { TelButton } from "@legacy_root/user_frontend/components/projects/button_contents/src/tel_button";
import * as styles from "./styles.css";

export const VerticallyStyleButtons: FC<ButtonProps> = ({
  workCode,
  clientShopId,
  capitalizePageType,
  isPreview,
  isApp,
  isSp,
  oiwaiInfomationProps,
  entryProps,
  keepProps,
  salonTourProps,
  telEntryProps,
  scoutProps,
  extendScoutProps,
  declineScoutProps,
}) => {
  const { handleClickSalonTourButton } = useSalonTourButton();

  const hasNotEntriedAndIsSalonTour = !entryProps.hasEntried && salonTourProps.isSalonTour;

  // 電話応募 表示条件
  const isShowTelButton =
    entryProps.isEnterable &&
    !entryProps.hasEntried &&
    !scoutProps.isScouted &&
    telEntryProps?.isShowTelButton &&
    telEntryProps?.telNumber;

  // 見学応募(secondary) 表示条件
  const isShowSecondarySalonTourLink = hasNotEntriedAndIsSalonTour && isSp && scoutProps.isScouted;

  // 見学応募(tertiary) 表示条件
  const isShowTertiarySalonTourLink =
    hasNotEntriedAndIsSalonTour && (!isSp || (isSp && !scoutProps.isScouted));

  return (
    <>
      <div className={styles.wrapper}>
        {oiwaiInfomationProps.isOiwaiDisplay && (
          <OiwaiInformationBox
            recruitmentRewardPrice={oiwaiInfomationProps.recruitmentRewardPrice}
            keepWorkRewardPrice={oiwaiInfomationProps.keepWorkRewardPrice}
          />
        )}
        <div className={styles.buttonWrapper}>
          {entryProps.hasEntried && <Button theme="disabled">応募済み</Button>}
          {entryProps.isEnterable && !entryProps.hasEntried && !scoutProps.isScouted && (
            <>
              <EntryLink
                text="応募画面へ進む"
                firebaseAction={entryProps.firebaseAction}
                url={entryProps.url}
                onClick={entryProps.onClick}
                gaAction={entryProps.gaAction}
                gaLabel={entryProps.gaLabel}
              />
              <div className={styles.keepWrapper}>
                {keepProps.isShowKeepBalloon && <KeepAppealPopOver />}
                <KeepForm
                  isPreview={isPreview}
                  url={keepProps.url}
                  workCode={workCode}
                  hasKeeped={keepProps.hasKeeped}
                  onClick={keepProps.onClick}
                  gaAction={keepProps.gaAction}
                  gaLabel={keepProps.gaLabel}
                />
              </div>
            </>
          )}
          {scoutProps.isScouted && (
            <>
              {extendScoutProps.scoutDeadline && (
                <ScoutDeadlineInformation remainingDaysNum={extendScoutProps.scoutDeadline} />
              )}
              {scoutProps.url && (
                <AcceptScoutLink text="スカウトを受ける" url={scoutProps.url} workCode={workCode} />
              )}
              {isShowSecondarySalonTourLink && (
                <div className={styles.salonTourButtonWrapper}>
                  <SalonTourButton
                    text="見学を希望する"
                    buttonTheme="secondary"
                    onClick={() =>
                      handleClickSalonTourButton(
                        isApp,
                        workCode,
                        scoutProps.isScouted,
                        clientShopId,
                        undefined,
                        undefined,
                        salonTourProps.url,
                      )
                    }
                  />
                </div>
              )}
              {!isApp && (
                <div className={styles.flexButtonWrapper}>
                  {extendScoutProps.isScoutExtend && !isApp && (
                    <ExtendScoutDeadlineButton
                      gaAction={extendScoutProps.gaAction}
                      gaLabel={extendScoutProps.gaLabel}
                      onClick={extendScoutProps.onClick}
                    >
                      <p>
                        スカウト期限
                        <br />
                        延長する
                      </p>
                    </ExtendScoutDeadlineButton>
                  )}
                  {!extendScoutProps.isScoutExtend && (
                    <Button theme="disabled">
                      スカウト期限
                      <br />
                      延長済み
                    </Button>
                  )}
                  <Link
                    theme="tertiary"
                    href={declineScoutProps.url}
                    className="js-scout-decline"
                    data-ga-action={declineScoutProps.gaAction}
                    data-ga-label={declineScoutProps.gaLabel}
                  >
                    辞退する
                  </Link>
                </div>
              )}
            </>
          )}
          {(isShowTelButton || isShowTertiarySalonTourLink) && (
            <div className={styles.telAndSalonWrapper}>
              {isShowTelButton && (
                <div className={styles.telButtonWrapper}>
                  <TelButton
                    isPreview={isPreview}
                    workCode={workCode}
                    clientShopId={clientShopId}
                    telNumber={telEntryProps.telNumber as string} // 「isShowTelButton」でundefinedではないこと（stringであること）を担保している
                    gaLabel={telEntryProps.gaLabel}
                    pageType="detail"
                    capitalizePageType={capitalizePageType}
                    position="bottom"
                  />
                  <p className={styles.workCode}>
                    求人番号: <b>{workCode}</b>
                  </p>
                </div>
              )}
              {isShowTertiarySalonTourLink && (
                <div className={styles.salonTourButtonWrapper}>
                  <SalonTourButton
                    text="見学を希望する"
                    buttonTheme="tertiary"
                    onClick={() =>
                      handleClickSalonTourButton(
                        isApp,
                        workCode,
                        scoutProps.isScouted,
                        clientShopId,
                        undefined,
                        undefined,
                        salonTourProps.url,
                      )
                    }
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
