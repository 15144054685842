import { FC } from "react";
import { OiwaiInformationBox } from "@userFrontend/components/OiwaiInfomationBox";
import { ConsiderationNumInformation } from "@userFrontend/features/publication/components/ConsiderationNumInformation";
import { ButtonContentsProps } from "@legacy_user_frontend/components/projects/button_contents/src/button_contents_props";
import { addQueryStrings } from "@userFrontend/utils/addQueryStrings";
import { KeepButtonContent } from "./src/keep_button_content";
import { ScoutButton } from "./src/scout_button";
import { TelButton } from "./src/tel_button";
import { FixButton } from "./src/fix_button";
import { TopContents } from "./src/top_contents";
import {
  StyledContentsOuter,
  StyledContents,
  StyledButtonGroup,
  StyledBottomContent,
  StyledEntriedButton,
  StyledBottomContents,
  StyledWorkCodeText,
  StyledEntryButton,
  StyledTitleText,
  StyledAwardsLabels,
  StyledAwardsLabel,
  StyledSalonTourButton,
} from "./src/styled_elements";

type Props = {
  btnPositionControllingClassName: string;
  handleClickSalonTourButton: (
    isApp: boolean,
    workCode: string,
    isScouted: boolean,
    clientShopId?: string | number,
    gaAction?: string,
    gaLabel?: string,
    salonTourEntryUrl?: string,
  ) => void;
  scoutGaLabel: string;
  awardsLabelContent?: {
    awardsLabelRank?: string | undefined;
    awardsLabelText?: string | undefined;
  };
  dataStyleColumn: 1 | 2;
} & Omit<
  ButtonContentsProps,
  "awardsLabel" | "awards2022Label" | "awards2023Label" | "awards2024Label"
>;

export const Presentation: FC<Props> = ({
  pageType,
  capitalizePageType,
  position,
  workCode,
  queryStr,
  isSp,
  isApp,
  clientShopId,
  gaLabel,
  title,
  imgSrc,
  dummyImgPath,
  isPreview,
  isContractRunning,
  oiwaiContents: { isOiwaiDisplay, recruitmentRewardPrice, keepWorkRewardPrice },
  considerationContents,
  buttonContents: {
    keepButtonContent,
    scoutButtonContent,
    entryButtonContent,
    salonTourButtonContent,
  },
  telNumberContent,
  awardsLabelContent,
  salaryContents,
  images,
  btnPositionControllingClassName,
  handleClickSalonTourButton,
  scoutGaLabel,
  dataStyleColumn,
}) => {
  // TODO:「見学を希望する」押下時にも「応募画面へ進む」押下時と同様に、URLにクエリを付与するようにするための応急処置。
  // 本来はロジックをこのファイルに書くべきではないが、EntryButtonで`js-add-query-strings`を使用しているため、似た処理を行うのでここに書いている。
  // legacy/からリファクタを行う際には、クエリ付与のタイミングやロジックの整理・共通化を必ず行うこと。
  const salonTourEntryUrlWithQuery = addQueryStrings(
    salonTourButtonContent.salonTourEntryUrl,
    queryStr,
  );

  return (
    <>
      <StyledContentsOuter
        data-position={position}
        is1column={
          !workCode || !entryButtonContent.isEnterable || (pageType !== "detail" && !imgSrc)
        }
      >
        {position === "top" && (
          <TopContents
            isSp={isSp}
            pageType={pageType}
            images={images}
            imgSrc={imgSrc}
            dummyImgPath={dummyImgPath}
            awardsLabel={awardsLabelContent}
            considerationContents={considerationContents}
            salaryContents={salaryContents}
            title={title}
            workCode={workCode}
            isEnterable={entryButtonContent.isEnterable}
            isContractRunning={isContractRunning}
          />
        )}
        {!workCode && <span className="js-footer-fix__first-point" />}
        {workCode && isContractRunning && (
          <StyledContents
            className={btnPositionControllingClassName}
            data-style-column={dataStyleColumn}
            data-position={position}
          >
            {isOiwaiDisplay && (
              <OiwaiInformationBox
                recruitmentRewardPrice={recruitmentRewardPrice}
                keepWorkRewardPrice={keepWorkRewardPrice}
              />
            )}
            <StyledButtonGroup
              data-style-column={dataStyleColumn}
              isBlock={scoutButtonContent.isScouted}
            >
              {entryButtonContent.hasEntried && (
                <StyledEntriedButton data-style-column={dataStyleColumn}>
                  応募済み
                </StyledEntriedButton>
              )}
              {entryButtonContent.isEnterable &&
                !entryButtonContent.hasEntried &&
                !scoutButtonContent.isScouted && (
                  <>
                    <StyledEntryButton
                      /* eslint no-script-url: 0 */
                      href={isPreview ? "javascript:void(0)" : entryButtonContent.entryUrl}
                      className="ga-click repro-click firebase-click disable-on-preview js-add-query-strings"
                      data-ga-action={`${pageType}_entry-${position}`}
                      data-ga-label={gaLabel}
                      data-repro-action={`trackTapJob${capitalizePageType}Entry`}
                      data-firebase-action="entry"
                      data-firebase-view-type="screen_view"
                      data-query-str={queryStr}
                      data-style-column={dataStyleColumn}
                      isFixed={false}
                    >
                      応募画面へ進む
                    </StyledEntryButton>
                    <KeepButtonContent
                      keepButtonContent={keepButtonContent}
                      workCode={workCode}
                      position={position}
                      gaLabel={gaLabel}
                      isPreview={isPreview}
                      pageType={pageType}
                      capitalizePageType={capitalizePageType}
                      dataStyleColumn={dataStyleColumn}
                    />
                  </>
                )}
              {scoutButtonContent.isScouted && (
                <ScoutButton
                  scoutButtonContent={scoutButtonContent}
                  salonTourButtonContent={salonTourButtonContent}
                  queryStr={queryStr}
                  scoutGaLabel={scoutGaLabel}
                  hasEntried={entryButtonContent.hasEntried}
                  workCode={workCode}
                  position={position}
                  isFixedButton={false}
                  pageType={pageType}
                  isApp={isApp}
                  isPreview={isPreview}
                  dataStyleColumn={dataStyleColumn}
                />
              )}
            </StyledButtonGroup>
            {!scoutButtonContent.isScouted && (
              <StyledBottomContents
                addMargin={telNumberContent.isShowTellButton}
                data-style-column={dataStyleColumn}
              >
                {isSp &&
                  telNumberContent.isShowTellButton &&
                  entryButtonContent.isEnterable &&
                  !entryButtonContent.hasEntried && (
                    <StyledBottomContent
                      is2column={
                        !scoutButtonContent.isScouted &&
                        salonTourButtonContent.isSalonTour &&
                        !entryButtonContent.hasEntried
                      }
                    >
                      <TelButton
                        telNumber={telNumberContent.telNumber}
                        position={position}
                        clientShopId={clientShopId}
                        gaLabel={gaLabel}
                        pageType={pageType}
                        capitalizePageType={capitalizePageType}
                        workCode={workCode}
                        isPreview={isPreview}
                      />
                      <StyledWorkCodeText>
                        求人番号: <b>{workCode}</b>
                      </StyledWorkCodeText>
                    </StyledBottomContent>
                  )}
                {!scoutButtonContent.isScouted &&
                  salonTourButtonContent.isSalonTour &&
                  !entryButtonContent.hasEntried && (
                    <StyledBottomContent is2column={telNumberContent.isShowTellButton && isSp}>
                      <StyledSalonTourButton
                        className="ga-click"
                        data-ga-action={`${pageType}_entry_tour-${position}`}
                        data-ga-label={gaLabel}
                        data-query-str={queryStr}
                        onClick={
                          !isPreview
                            ? () =>
                                handleClickSalonTourButton(
                                  isApp,
                                  workCode,
                                  scoutButtonContent.isScouted,
                                  clientShopId,
                                  `${pageType}_entry_tour-${position}`,
                                  gaLabel,
                                  // APPの場合は何もパラメータを付けない
                                  isApp
                                    ? salonTourButtonContent.salonTourEntryUrl
                                    : salonTourEntryUrlWithQuery,
                                )
                            : undefined
                        }
                      >
                        <i className="icon-kengaku" />
                        見学を希望する
                      </StyledSalonTourButton>
                    </StyledBottomContent>
                  )}
              </StyledBottomContents>
            )}
          </StyledContents>
        )}
      </StyledContentsOuter>
      {!isSp && position === "top" && (
        <>
          {workCode && pageType === "detail" && (
            <>
              {awardsLabelContent && (
                <StyledAwardsLabels>
                  <StyledAwardsLabel data-rank={awardsLabelContent.awardsLabelRank}>
                    {awardsLabelContent.awardsLabelText}
                  </StyledAwardsLabel>
                </StyledAwardsLabels>
              )}
              {considerationContents.considerationDisplayFlag && entryButtonContent.isEnterable && (
                // !isSpの場合(PC時想定)に表示される
                <ConsiderationNumInformation
                  considerationNumText={considerationContents.considerationNumText}
                />
              )}
              <StyledTitleText>{title}</StyledTitleText>
            </>
          )}
        </>
      )}
      {position === "top" &&
        isSp &&
        !entryButtonContent.hasEntried &&
        !isPreview &&
        isContractRunning &&
        entryButtonContent.isEnterable && (
          <FixButton
            scoutButtonContent={scoutButtonContent}
            entryButtonContent={entryButtonContent}
            telNumberContent={telNumberContent}
            gaLabel={gaLabel}
            queryStr={queryStr}
            workCode={workCode}
            scoutGaLabel={scoutGaLabel}
            clientShopId={clientShopId}
            pageType={pageType}
            capitalizePageType={capitalizePageType}
            isApp={isApp}
            dataStyleColumn={dataStyleColumn}
            isPreview={isPreview}
          />
        )}
    </>
  );
};
