/* eslint-disable react/jsx-props-no-spreading */
import { FC, lazy, Suspense, useCallback, useEffect, useRef, useState } from "react";
import { useForm, FormProvider, useFieldArray, SubmitHandler } from "react-hook-form";
import { SubmitAndCancelButtons } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/submit_and_cancel_buttons";
import { BoxWithShadow } from "@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/box_with_shadow";
import { showAlertBeforeBrowserBack } from "@legacy_root/user_frontend/components/pages/mypage/profiles/utils/show_alert_before_browser_back";
import { InputProps, ProductPhotoEditProps } from "./src/product_photo_edit_props";
import { Photo } from "./ui_parts/photo";
import { ImageUploader } from "./ui_parts/image_uploader";
import {
  StyledDescription,
  StyledImagesOuter,
  StyledList,
  StyledSmallDescription,
  StyledImageUploaderOuter,
  StyledConfirmedRemark,
} from "./src/styled_elements";
import { textAndLineBreakLength } from "./src/modules";

const CancelModal = lazy(
  () => import("@legacy_user_frontend/components/pages/mypage/profiles/ui_parts/cancel_modal"),
);

export const ProductPhotoEdit: FC<ProductPhotoEditProps> = ({
  action,
  productPhotos,
  uploadPath,
  errors,
  validation,
  returnPath,
  isBiyo,
  appOsType,
}) => {
  // 初期値の設定
  const methods = useForm<InputProps>({
    defaultValues: {
      authenticity_token: "",
      _method: "put",
      product_photos_update: {
        product_photos_attributes: productPhotos,
      },
    },
  });
  const [previewImages, setPreviewImages] = useState<string[]>(
    productPhotos.map(({ imageUrl }) => imageUrl),
  );

  // エラーメッセージの設定
  const { setError } = methods;
  const setErrorMessage = useCallback(
    (key: string, index: number, errorObject: Record<string, string>) => {
      const errorFormName =
        `product_photos_update.product_photos_attributes.${index}.${key}` as keyof InputProps;
      setError(
        errorFormName,
        {
          type: "manual",
          message: errorObject[key],
        },
        { shouldFocus: true },
      );
    },
    [setError],
  );
  useEffect(() => {
    Object.keys(errors).forEach((key, index) => {
      setErrorMessage(key, index, errors);
    });
    productPhotos.forEach((photo, photoIndex) => {
      const photoErrors = photo.errors;
      const photoErrorKeys = Object.keys(photoErrors);
      photoErrorKeys.map((photoKey) => setErrorMessage(photoKey, photoIndex, photoErrors));
    });
  }, [errors, productPhotos, setErrorMessage]);

  // 画像のfieldArray作成
  const { fields, remove, append } = useFieldArray({
    control: methods.control,
    name: "product_photos_update.product_photos_attributes",
  });

  // 画像の追加をfieldArrayに反映
  const appendFields = (imageId: string, imageUrl: string) => {
    append({ image: imageId, description: "" });
    setPreviewImages([...previewImages, imageUrl]);
    methods.setValue(
      `product_photos_update.product_photos_attributes.${fields.length}.image`,
      imageId,
    );
  };

  // 画像の削除をfieldArrayに反映
  const removeFields = (index: number, image: string) => {
    remove(index);
    setPreviewImages(previewImages.filter((previewImage) => previewImage !== image));
  };

  // 変更の検出
  const watchedValues = methods.watch();
  const [isChanged, setIsChanged] = useState(false);
  useEffect(() => {
    if (
      JSON.stringify(watchedValues.product_photos_update.product_photos_attributes) !==
      JSON.stringify(productPhotos)
    ) {
      setIsChanged(true);
    } else {
      setIsChanged(false);
    }
  }, [productPhotos, watchedValues]);

  // キャンセルボタン押下時に確認モーダルを表示する
  const [isShowAlert, setIsShowAlert] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    if (isChanged) {
      setIsModalOpen(true);
      setIsShowAlert(false);
      return;
    }
    window.location.href = returnPath;
  };
  const closeModal = () => {
    setIsShowAlert(true);
    setIsModalOpen(false);
  };

  // ブラウザバックを検知して確認モーダルを表示する
  const ua = window.navigator.userAgent.toLowerCase();
  useEffect(() => {
    // 変更がない場合のほか、safariとiOSの場合（画面遷移後の動作に影響があるためブラウザバックを許容）は処理を抜ける
    if (
      ua.indexOf("iphone") > 0 ||
      ua.indexOf("ipod") > 0 ||
      (ua.indexOf("chrome") === -1 && ua.indexOf("safari") !== -1) ||
      !isChanged
    ) {
      return;
    }
    showAlertBeforeBrowserBack({ setIsShowAlert, setIsModalOpen });
  }, [isChanged, ua]);

  // 画面遷移を検知して確認アラートを表示する
  useEffect(() => {
    const showAlert = (event: BeforeUnloadEvent) => {
      if (!isChanged || !isShowAlert) {
        return;
      }
      // eslint-disable-next-line no-param-reassign
      event.returnValue = "";
    };
    window.addEventListener("beforeunload", showAlert);
    return () => window.removeEventListener("beforeunload", showAlert);
  }, [isChanged, isShowAlert]);

  // 送信処理
  const descriptionMaxLength = validation.description.maxLength;
  const formEl = useRef<HTMLFormElement>(null);
  const onSubmit: SubmitHandler<InputProps> = (data) => {
    let hasErrors = false;
    data.product_photos_update.product_photos_attributes.forEach(({ description }, index) => {
      // サーバ側と合わせるため改行は2文字として扱う
      // 改行コードが1文字分の場合と2文字分の場合があるため、改行なしの文字数と改行の数を足す
      if (description && textAndLineBreakLength(description) > descriptionMaxLength) {
        methods.setError(
          `product_photos_update.product_photos_attributes.${index}.description`,
          {
            type: "manual",
            message: `説明は${descriptionMaxLength}文字以内で入力してください。`,
          },
          { shouldFocus: true },
        );
        hasErrors = true;
      }
    });
    if (hasErrors) {
      return;
    }
    setIsShowAlert(false);
    formEl.current?.submit();
  };

  return (
    <>
      <FormProvider {...methods}>
        <form ref={formEl} action={action} onSubmit={methods.handleSubmit(onSubmit)} method="post">
          <input type="hidden" {...methods.register("authenticity_token")} />
          <input type="hidden" {...methods.register("_method")} />
          <BoxWithShadow>
            <>
              <StyledDescription>
                {isBiyo ? (
                  <>
                    スタイル、デザインなど、あなたを表現する写真を追加してください。
                    {validation.productPhotos.maxCount}件まで追加できます。
                  </>
                ) : (
                  <>
                    あなたの仕事風景や、仕事に対する姿勢が想像できる写真を追加してください。
                    {validation.productPhotos.maxCount}件まで追加できます。
                    <br />
                    例）前職の様子（レクリエーション、利用者様とのふれあい）など
                  </>
                )}
              </StyledDescription>
              <StyledSmallDescription>
                {!isBiyo && (
                  <span>
                    ※被写体に人物（利用者様や未成年の方など）が含まれている場合は、事前にその方の承諾を得るなど、肖像権の侵害等が生じないように自己の責任においてご確認ください。
                  </span>
                )}
                <span>
                  ※以下に違反している内容については、ユーザーへの同意なしに内容の変更または削除を行う可能性があります。
                  <br />
                  １．個人が特定できる
                  <br />
                  ２．著作権､プライバシー権､肖像権､名誉等の他者の権利を侵害している
                </span>
              </StyledSmallDescription>
              <StyledList>
                <li>・幅592×高さ1052ピクセル以上の画像にしてください</li>
                <li>・形式はJPGかPNGの画像にしてください</li>
                <li>・20MB以下の画像にしてください</li>
              </StyledList>
              <StyledImageUploaderOuter>
                <ImageUploader
                  uploadPath={uploadPath}
                  imageLength={fields.length}
                  appendFields={appendFields}
                  validation={validation}
                />
              </StyledImageUploaderOuter>
              {fields.length > 0 && (
                <StyledImagesOuter>
                  {fields.map(({ id }, index) => (
                    <Photo
                      key={id}
                      photoIndex={index}
                      maxLength={descriptionMaxLength}
                      imageUrl={previewImages[index]}
                      remove={removeFields}
                    />
                  ))}
                </StyledImagesOuter>
              )}
            </>
          </BoxWithShadow>
          <StyledConfirmedRemark>
            上記に同意したうえで「保存する」を押してください。
          </StyledConfirmedRemark>
          <SubmitAndCancelButtons handleOnClick={openModal} appOsType={appOsType} />
        </form>
      </FormProvider>
      <Suspense fallback={null}>
        {isModalOpen && (
          <CancelModal isModalOpen={isModalOpen} closeModal={closeModal} returnPath={returnPath} />
        )}
      </Suspense>
    </>
  );
};
